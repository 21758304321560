import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import('@/views/Home.vue'),
        meta: {
            title: 'max-pdf工具集'
        }
    },
    {
        path: '/convert',
        name: 'Convert',
        component: () => import('@/views/Convert.vue'),
        meta: {
            title: 'max-pdf工具集'
        }
    },
    {
        path: '/authorize',
        name: 'Authorize',
        component: () => import('@/views/Authorize.vue'),
        meta: {
            title: 'max-pdf工具集-用户协议'
        }
    },
]

const router = new VueRouter({
    routes
})

export default router
