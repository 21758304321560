import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import {createPinia, PiniaVuePlugin} from 'pinia'
import piniaPluginPersist from 'pinia-plugin-persist'
import Vuesax from 'vuesax'
import GlobalComponents from '@/components/index.js'

import 'vuesax/dist/vuesax.css'
import 'boxicons/css/boxicons.min.css'

const pinia = createPinia()
pinia.use(piniaPluginPersist)

Vue.use(Vuesax)
Vue.use(PiniaVuePlugin)
Vue.use(GlobalComponents)
Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title
    }
    next()
})

new Vue({
    router,
    pinia,
    render: h => h(App)
}).$mount('#app')

