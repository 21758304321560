import BottomNavbar from '@/components/navbar/BottomNavbar';
import TopNavbar from '@/components/navbar/TopNavbar';

const GlobalComponents = {
    install: function (Vue) {
        Vue.component('BottomNavbar', BottomNavbar)
        Vue.component('TopNavbar', TopNavbar)
    }
}

export default GlobalComponents;