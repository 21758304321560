const fileConvertData = [
    {
        "id": 1,
        "title": "PDF 转 Word",
        "state": true,
        "tag": "pdf;format-tool;pdf 转 office",
        "class": "tool-card",
        "description": "将 .pdf 文件转换为 .docx 格式",
        "convertFormat": "docx",
        "sourceFormat": "pdf",
        "details": "(可编辑.docx文件)",
        "image": "pdf-to-word.svg"
    },
    {
        "id": 2,
        "title": "PDF 转 HTML",
        "state": true,
        "tag": "pdf;format-tool;pdf 转 office",
        "class": "tool-card",
        "description": "将 .pdf 文件转换为 .html 格式",
        "convertFormat": "html",
        "sourceFormat": "pdf",
        "details": "(包含多份样式文件)",
        "image": "pdf-to-html.svg"
    },
    {
        "id": 3,
        "title": "PDF 转 PNG",
        "state": true,
        "tag": "pdf;format-tool;pdf 转 office",
        "class": "tool-card",
        "description": "将 .pdf 文件转换为 .png 格式",
        "convertFormat": "png",
        "sourceFormat": "pdf",
        "details": "(一页内容一张图片)",
        "image": "pdf-to-png.svg"
    },
    {
        "id": 4,
        "title": "PDF 转 PPT",
        "state": true,
        "tag": "pdf;format-tool;pdf 转 office",
        "class": "tool-card",
        "description": "将 .pdf 文件转换为 .ppt 格式",
        "convertFormat": "ppt",
        "sourceFormat": "pdf",
        "details": "(可编辑.ppt文件)",
        "image": "pdf-to-ppt.svg"
    },
    {
        "id": 5,
        "title": "PDF 转 Excel",
        "state": true,
        "tag": "pdf;format-tool;pdf 转 office",
        "class": "tool-card",
        "description": "将 .pdf 文件转换为 .xls 格式",
        "convertFormat": "xls",
        "sourceFormat": "pdf",
        "details": "(可编辑.xls文件)",
        "image": "pdf-to-xls.svg"
    },
    {
        "id": 6,
        "title": "PDF 转 Word (旧)",
        "state": true,
        "tag": "pdf;format-tool;pdf 转 office",
        "class": "tool-card",
        "description": "将 .pdf 文件转换为 .doc 格式",
        "convertFormat": "doc",
        "sourceFormat": "pdf",
        "details": "(doc可兼容更老的office2003)",
        "image": "pdf-to-doc.svg"
    },
    {
        "id": 7,
        "title": "PDF 转 TXT",
        "state": true,
        "tag": "pdf;format-tool;pdf 转 office",
        "class": "tool-card",
        "description": "将 .pdf 文件转换为 .txt 格式",
        "convertFormat": "txt",
        "sourceFormat": "pdf",
        "details": "(将文字提取到txt文本文件中)",
        "image": "pdf-to-txt.svg"
    },
    {
        "id": 8,
        "title": "PDF 转 APS",
        "state": true,
        "tag": "pdf;format-tool;pdf 转 office",
        "class": "tool-card",
        "description": "将 .pdf 文件转换为 .aps 格式",
        "convertFormat": "aps",
        "sourceFormat": "pdf",
        "details": "(可编辑.aps文件)",
        "image": "pdf-to-aps.svg"
    },
    {
        "id": 9,
        "title": "PDF 转 Epub",
        "state": true,
        "tag": "pdf;format-tool;pdf 转 office",
        "class": "tool-card",
        "description": "将 .pdf 文件转换为 .epub 格式",
        "convertFormat": "epub",
        "sourceFormat": "pdf",
        "details": "(可编辑.epub文件)",
        "image": "pdf-to-epub.svg"
    },
    {
        "id": 10,
        "title": "图片压缩",
        "state": false,
        "tag": "image;image-tool;图片相关工具",
        "class": "tool-card disable-tool",
        "description": "进行png/jpg图片压缩",
        "convertFormat": "png",
        "sourceFormat": "png",
        "details": "(敬请期待)",
        "image": "png-compress.svg"
    },
    {
        "id": 11,
        "title": "图片文字提取",
        "state": false,
        "tag": "image;image-tool;图片相关工具",
        "class": "tool-card disable-tool",
        "description": "提取图片中的文本信息",
        "convertFormat": "png",
        "sourceFormat": "txt",
        "details": "(敬请期待)",
        "image": "png-to-txt.svg"
    },
    {
        "id": 12,
        "title": "Word 转 PDF",
        "state": false,
        "tag": "office;format-tool;office 转 pdf",
        "class": "tool-card disable-tool",
        "description": "将 .docx 文件转换为 .pdf 格式",
        "convertFormat": "pdf",
        "sourceFormat": "docx",
        "details": "(敬请期待)",
        "image": "docx-to-pdf.svg"
    },
    {
        "id": 13,
        "title": "PNG 转 PDF",
        "state": false,
        "tag": "image;format-tool;图片 转 pdf",
        "class": "tool-card disable-tool",
        "description": "将 .png 文件转换为 .pdf 格式",
        "details": "(敬请期待)",
        "convertFormat": "pdf",
        "sourceFormat": "png",
        "image": "png-to-pdf.svg"
    },
    {
        "id": 14,
        "title": "PPT 转 PDF",
        "state": false,
        "tag": "office;format-tool;office 转 pdf",
        "class": "tool-card disable-tool",
        "description": "将 .ppt 文件转换为 .pdf 格式",
        "convertFormat": "pdf",
        "sourceFormat": "ppt",
        "details": "(敬请期待)",
        "image": "ppt-to-pdf.svg"
    },
    {
        "id": 15,
        "title": "PDF 加密",
        "state": false,
        "tag": "pdf-tool;pdf工具",
        "class": "tool-card disable-tool",
        "description": "将 .ppt 文件进行密码加密",
        "convertFormat": "pdf",
        "sourceFormat": "pdf",
        "details": "(敬请期待)",
        "image": "pdf-encryption.svg"
    }
]

export const dataFilterByTag = (tag) => {
    return fileConvertData.filter((function (data) {
        const tagList = data.tag.split(";").filter(function (tag_data) {
            return tag_data === tag
        })
        return tagList.length !== 0
    }))
}

export const dataFilterByName = (name) => {
    if (!name) {
        return null
    }
    if (name === '') {
        return null
    }
    name = name.toLowerCase()
    const dataList = fileConvertData.filter((function (data) {
        const title = data.title.toLowerCase()
        return title.indexOf(name) > -1
    }))
    if (dataList.length > 5) {
        // 只要前5条匹配信息
        return dataList.splice(0, 5)
    }
    return dataList
}

export default fileConvertData