<template>
  <div class="bottom">
    <div class="expand">
      <div>MaxPDF.cn</div>
      <ul>
        <li>产品</li>
        <li>应用程序和集成</li>
        <li>技术</li>
      </ul>
      <ul>
        <li>公司</li>
        <li>支持/帮助中心</li>
      </ul>
    </div>
    <div class="declare">
      <div v-text="statement"></div>
      <div>
        <span class="company">重庆亚诺尔科技有限公司</span>
        <a href="https://beian.miit.gov.cn" target="_blank">渝ICP备20000288号-6</a>
        <div style="margin-left: 30px">
          <img :src="require('@/assets/logo-ga.png')" width="20px" alt="" style="margin-right: 4px">
          <a href="https://beian.mps.gov.cn/#/query/webSearch?code=50011302222242" target="_blank">渝公网安备50011302222243号</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BottomNavbar",
  data: () => ({
    statement: "© 2022 maxpdf.cn",
  }),
  created() {
    this.statement = "© " + new Date().getFullYear() + " maxpdf.cn"
  },
};
</script>

<style scoped lang="scss">
.bottom {
  width: 100%;
  background: #fff;
  padding: 60px 0 45px 0;

  .expand,
  .declare {
    width: 1100px;
    margin: auto;
  }

  .declare {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding-top: 20px;
    font-size: 13px;
    color: #656d77;

    div {
      display: flex;
    }

    a {
      color: #3399ff;
      text-decoration: none;
    }
  }

  .expand {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0 60px 0 0;
      font-size: 15px;

      li:first-child {
        font-weight: bold;
      }

      li {
        margin-bottom: 30px;
      }
    }

    ul:last-child {
      padding: 0;
    }

    div {
      position: absolute;
      left: 0;
      font-size: 26px;
      color: darkgray;
    }
  }
}

.company {
  margin-right: 30px;
}
</style>
