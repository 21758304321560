<template>
  <div class="top">
    <div class="navbar">
      <vs-navbar not-line center-collapsed v-model="active">
        <template #left>
          <div>
            <img @click="gotoHome" class="logo" src="@/assets/logo.png" alt="logo"/>
          </div>
          <vs-navbar-group>
            所有工具
            <template #items>
              <vs-navbar-item :active="active === 'all'" @click="toolFilter('')" id="all">
                全部
              </vs-navbar-item>
              <vs-navbar-item :active="active === 'pdf'" @click="toolFilter('pdf')" id="pdf">
                pdf 转 office
              </vs-navbar-item>
              <vs-navbar-item :active="active === 'office'" @click="toolFilter('office')" id="office">
                office 转 pdf
              </vs-navbar-item>
              <vs-navbar-item :active="active === 'image'" @click="toolFilter('image')" id="image">
                图片工具
              </vs-navbar-item>
            </template>
          </vs-navbar-group>
          <vs-navbar-item :active="active === 'format-tool'" @click="toolFilter('format-tool')" id="format-tool">
            格式转换
          </vs-navbar-item>
          <vs-navbar-item :active="active === 'pdf-tool'" @click="toolFilter('pdf-tool')" id="pdf-tool">
            PDF工具
          </vs-navbar-item>
          <vs-navbar-item :active="active === 'image-tool'" @click="toolFilter('image-tool')" id="image-tool">
            图片工具
          </vs-navbar-item>
        </template>

        <template #right>
          <vs-input class="search-input"
                    type="text"
                    color="#3399ff"
                    icon-after
                    aria-label="Search"
                    v-model="toolSearchVal"
                    @input="toolSearch"
                    placeholder="搜索工具集">
            <template #icon>
              <i class='bx bx-search-alt-2'></i>
            </template>
          </vs-input>
        </template>
        <div class="search-div">
          <div v-for="(fileConvert,index) in toolSearchDataList" :key="index">
            <vs-card @click="gotoConvert(fileConvert)"
                     :class="fileConvert.class"
                     type="3">
              <template #title>
                <h3>{{ fileConvert.title }}</h3>
              </template>
              <template #text>
                <p> {{ fileConvert.description }}</p>
              </template>
            </vs-card>
          </div>
        </div>
      </vs-navbar>
    </div>
  </div>
</template>

<script>
import convertStore from '@/store/convert'
import {dataFilterByName} from '@/config/file-convert-data'

export default {
  name: "TopNavbar",
  data: () => ({
    active: "guide",
    toolSearchVal: "",
    toolSearchDataList: null
  }),
  methods: {
    gotoHome() {
      convertStore().filterFileConvertByTag('')
      if (this.$route.path !== '/') {
        this.$router.replace('/')
      }
    },
    toolFilter(tag) {
      convertStore().filterFileConvertByTag(tag)
      if (this.$route.path !== '/') {
        this.$router.replace('/')
      }
    },
    toolSearch() {
      this.toolSearchDataList = dataFilterByName(this.toolSearchVal)
    },
    gotoConvert(fileConvert) {
      this.toolSearchVal = ''
      this.toolSearchDataList = null
      if (!fileConvert.state) {
        return
      }
      convertStore().setFileConvert(fileConvert)
      if (this.$route.path !== '/convert') {
        this.$router.replace('/convert')
      }
    }
  }
};
</script>

<style scoped lang="scss">
.top {
  height: 44px;
  padding: 15px 0 15px 0;
  position: fixed;
  z-index: 9999;
  background: #fff;
  width: 100%;
  min-width: 1300px;
}

::v-deep .vs-navbar-content {
  color: black;
}

.logo {
  height: 40px;
  cursor: pointer;
}

.navbar {
  width: 1100px;
  margin: auto;
  position: relative;
  height: 100%;
}

::v-deep .search-input input {
  height: 37px;
}

::v-deep .search-input input:focus {
  width: 280px;
  border: 2px solid transparent;
}

::v-deep .vs-navbar {
  padding: 0;
}

.search-div {
  position: absolute;
  right: 0;
  z-index: 9999;
  top: 50px;
  background: white;
  width: 300px;
  border-radius: 20px;
}

::v-deep .search-div {
  .vs-card {
    -webkit-box-shadow: none;
    box-shadow: none;

    .vs-card__text {
      .vs-card__title {
        h3 {
          font-size: 12px !important;
        }
      }

      p {
        font-size: 12px;
      }

    }
  }
}

::v-deep .disable-tool {
  .vs-card {
    cursor: no-drop !important;
    opacity: 0.7;
  }
}

::v-deep .vs-navbar__item, ::v-deep .vs-navbar__group, ::v-deep .vs-navbar__group__item {
  font-size: 0.92rem;
  color: black;
  opacity: 1;
}

::v-deep .vs-navbar__item:hover, ::v-deep .vs-navbar__group:hover, ::v-deep .vs-navbar__group__item:hover {
  color: #3399ff;
  transition: all 0.4s;
}

::v-deep .vs-navbar__group__item, ::v-deep .vs-navbar__item {
  padding: 10px;
  margin-left: 20px;
}

</style>
