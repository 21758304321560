import {defineStore} from 'pinia'
import fileConvertData, {dataFilterByTag} from '@/config/file-convert-data'


const convertStore = defineStore('convertStore', {
    state: () => ({
        fileConvert: {
            "title": "PDF 转 Word",
            "state": true,
            "tag": "pdf",
            "class": "tool-card",
            "description": "将 .pdf 文件转换为 .docx 格式",
            "convertFormat": "docx",
            "sourceFormat": "pdf",
            "details": "(可编辑.docx文件)",
            "image": "@/assets/pdf-to-jpg-color.svg"
        },
        fileConvertList: fileConvertData
    }),
    actions: {
        setFileConvert(data) {
            this.fileConvert = data
        },
        getFileConvert() {
            return this.fileConvert
        },
        filterFileConvertByTag(tag) {
            if (tag === '') {
                this.fileConvertList = fileConvertData
                return
            }
            this.fileConvertList = dataFilterByTag(tag)
        }
    },
    persist: {
        enabled: true,
        strategies: [
            {
                key: 'convert_store',
                storage: localStorage,
                paths: ['fileConvert'],
            },
        ],

    }
})

export default convertStore